
























import FInput from '@/components/Form/FInput.vue'
import FSelect from '@/components/Form/FSelect.vue'
import FForm from '@/components/Form/FForm.vue'
import FButton from '@/components/Button/FButton.vue'
import { Component, Vue } from 'vue-property-decorator'
import { SearchSongService } from '@/services/SearchSongService'
import { i18n } from '@/main'
import { DynamicTranslationService } from '@/services/DynamicTranslationService'

@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.searchAllSongsTitle
  }), 
  components:{ 
    FInput,
    FButton,
    FSelect,
    FForm,
  }
})
export default class SongSearchBar extends Vue {
  store = SearchSongService;

  onSubmit(){ 
    SearchSongService.updateSearchText(this.store.query.searchText);
  }

  beforeDestroy() { 
    this.store.query.searchText = '';
  }
}
